header {
  background-color: #fff;
  height: 10vh;
  box-shadow: 0 5px 30px rgb(0 22 84 / 10%);
  padding: 15px 20px;
  position: sticky;
  top: 0;
  z-index: 99999;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

header .logo img {
  width: 170px;
}

header .nav {
  display: flex;
  align-items: center;
}

header .nav ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

header .nav ul li {
  margin-left: 30px;
}

header .nav ul li a {
  transition: 0.5s;
  font-weight: 500;
}

header .nav ul li a:hover {
  color: #27ae60;
}

header .toggle {
  display: none;
  font-size: 24px;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  header .nav ul.flex {
    display: none;
  }
  
  header .nav ul.small {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 10vh;
    right: 0;
    background-color: #27ae60;
    padding: 20px;
    width: 100%;
  }

  header .nav ul.small li {
    margin: 10px 0;
  }

  header .nav ul.small li a {
    color: #fff;
    font-size: 18px;
  }

  header .toggle {
    display: block;
  }
}
