.about {
  margin-bottom: 80px;
}

.about .heading {
  text-align: left;
  width: 100%;
  margin-bottom: 30px;
}

.about p {
  font-size: 15px;
  padding-right: 30px;
  margin-bottom: 20px;
  line-height: 30px;
}

/* Move section up on smaller screens */
@media screen and (max-width: 768px) {
  .about {
    margin-top: -20px; /* Adjust this value to move the section further up */
  }
  .about .container {
    flex-direction: column;
    padding: 0 15px; /* Add padding to the container */
  }
  .about p {
    padding: 0;
  }
  .about .container img {
    margin-top: 50px;
  }
}
