/* General Styles */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.property {
  text-align: center;
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}

/* Property Card */
.property-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
}

.property-heading h2 {
  font-size: 2rem;
  margin-bottom: 10px;
}

.property-heading h4 {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 20px;
}

/* Property Details */
.property-details {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 30px;
  text-align: left;
}

.detail-item {
  display: flex;
  align-items: center;
}

.detail-item p {
  margin-left: 10px;
  font-size: 1rem;
}

.detail-icon {
  width: 32px;
  height: 32px;
}

/* Smoking, Pet, and Children Info */
.property-info {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #ddd;
}

.info-item {
  display: flex;
  align-items: center;
  font-size: 1rem;
}

.info-item p {
  margin-left: 10px;
  font-size: 1rem;
}

/* Images */
.main-image {
  padding-top: 20px;
  padding-bottom: 20px;
}

.main-image img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  object-fit: cover;
}

.thumbnail-images {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.thumbnail-images img {
  width: 100px;
  height: 75px;
  object-fit: cover;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.thumbnail-images img:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.thumbnail-images img.active {
  border: 2px solid #4CAF50;
}

/* Property Description */
.property-description {
  text-align: left;
  margin-top: 20px;
  margin-bottom: 30px;
  font-size: 1.1rem;
  padding-top: 20px;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  max-width: 2200px; /* Set the max width for the description */
  word-wrap: break-word; /* Ensure long words break properly */
}

.property-description p {
  margin: 10px 0;
}

/* Apply Text and Contact Icons */
.apply {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.apply-text {
  font-weight: bold;
  color: #000;
  font-size: 16px;
  cursor: pointer;
  transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
  text-align: center;
  display: inline-block;
}

.apply-text:hover {
  color: #333;
  transform: scale(1.05);
}

.contact-icons {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.contact-icons img {
  width: 32px;
  height: 32px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.contact-icons img:hover {
  transform: scale(1.1);
}

/* Not Found Message */
.not-found {
  font-size: 1.5rem;
  color: #f00;
  text-align: center;
  margin-top: 50px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .thumbnail-images {
    gap: 10px;
  }

  .thumbnail-images img {
    width: 80px;
    height: 60px;
  }

  .apply-text {
    font-size: 14px;
    padding: 10px 20px;
  }
}
