.hero {
  background-image: url("../../../../public/images/banner.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 90vh;
  width: 100%;
}

/* Adjusted padding for desktop screens */
.hero .container {
  padding-top: 10%; /* Move content up for larger screens */
}

.hero h1 {
  color: #fff;
  font-size: 40px; /* Adjusted font size */
}

.hero p {
  color: #fff;
  opacity: 0.8;
}

.hero .verification-note {
  padding-top: 20px;
  color: #fff;
  opacity: 0.8;
  margin-top: 10px;
  font-size: 16px; /* Adjust the font size as needed */
  text-align: center; /* Center align the text */
}

form {
  background-color: #fff;
  border-radius: 5px;
  margin-top: 40px;
  border-radius: 15px;
}

form input {
  width: 100%;
  border: 1px solid rgba(128, 128, 128, 0.2);
  margin-top: 5px;
  border-radius: 5px;
}

form span {
  font-size: 14px;
  color: rgb(255, 248, 248);
}

input::placeholder {
  font-size: 17px;
  color: rgb(255, 255, 255);
}

form .box {
  padding: 7px;
  border-left: 1px solid rgba(128, 128, 128, 0.2);
  width: 110%;
  margin-bottom: 0px;
}

form .box:nth-child(1) {
  border-left: none;
}

form h4 {
  font-weight: 500;
}

/* Adjusted layout for smaller screens */
@media screen and (max-width: 800px) {
  .hero .container {
    padding-top: 20%; /* Move content higher for medium screens */
  }

  form {
    display: flex;
    flex-wrap: wrap;
  }

  form .box {
    border-left: none;
  }

  form input {
    width: 100%;
  }
}

/* Move elements up on small screens */
@media screen and (max-width: 600px) {
  .hero .container {
    padding-top: 15%; /* Move content up for small screens */
  }

  .advertising-card {
    margin-top: 5px; /* Adjusted margin to move up */
    padding: 15px;
  }

  form {
    flex-direction: column; /* Stack items vertically */
  }

  form .box {
    border-left: none; /* Remove left border for stacked layout */
  }

  form input {
    width: 100%; /* Full width inputs */
  }

  /* Additional adjustments for smaller screens */
}

.advertising-card {
  margin-top: 10px; /* Adjusted margin-top to move button up */
  padding: 20px;
  text-align: center;
}

.advertising-card h2 {
  margin-bottom: 10px;
  color: #fff;
}

.advertising-card p {
  margin-bottom: 20px;
}

.advertising-card .btn1 {
  text-decoration: none;
  color: #fff;
  background-color: #27ae60;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.advertising-card .btn1:hover {
  background-color: #14b055;
}
