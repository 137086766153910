.recent {
  padding: 20px;
}

.recent .filter-sort-container {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  margin-bottom: 20px; /* Space below the filter-sort container */
}

.max-rent-filter {
  display: flex;
  align-items: center; /* Center items vertically */
}


.max-rent-filter input {
  padding: 10px;
  font-size: 16px;
  border-radius: 8px;
  border: 1px solid #ddd;
  margin-right: 10px; /* Space between input and button */
}

.max-rent-filter button {
  padding: 10px 15px;
  background-color: #25b579;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background 0.3s ease;
}

.max-rent-filter button:hover {
  background-color: #1c944e;
}

/* Media query for smaller screens */
@media (max-width: 600px) {
  .max-rent-filter {
    flex-direction: column; /* Stack input and button vertically */
    align-items: flex-start; /* Align items to the start */
  }

  .max-rent-filter input {
    margin-bottom: 10px; /* Space below the input */
    margin-right: 0; /* Remove right margin */
  }
}

.recent .sort-dropdown {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.sort-dropdown label {
  margin-right: 10px;
  font-weight: bold;
  color: #333;
  font-size: 16px;
}

.sort-dropdown select {
  padding: 10px 15px;
  font-size: 16px;
  border-radius: 8px;
  border: 1px solid #ddd;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: border 0.3s ease, box-shadow 0.3s ease;
}

.sort-dropdown select:hover {
  border-color: #25b579;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
}

.sort-dropdown select:focus {
  outline: none;
  border-color: #25b579;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.recent .content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.recent .box {
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  display: flex;
  flex-direction: column;
}

.recent .box:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.recent .img {
  position: relative;
  height: 200px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.recent .img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.recent .img img:hover {
  transform: scale(1.1);
}

.carousel-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 18px;
  z-index: 10;
}

.prev-btn {
  left: 10px;
}

.next-btn {
  right: 10px;
}

.carousel-btn:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.recent .text {
  padding: 15px;
  background-color: #f9f9f9;
}

.recent .category {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.recent .category span {
  padding: 6px 12px;
  border-radius: 20px;
  font-weight: 600;
  font-size: 14px;
  background: #e0eaff;
}

.recent .verified {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  color: #25b579;
}

.recent .verified i {
  font-size: 20px;
  color: #25b579;
}

.recent h4 {
  font-size: 18px;
  color: #333;
  margin: 10px 0;
}

.recent p {
  color: #72809d;
  font-size: 14px;
  line-height: 1.6;
}

.recent .button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-top: 1px solid #e6e9ec;
  background: #fff;
}

.recent .btn2 {
  background: #25b579;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background 0.3s ease;
}

.recent .btn2:hover {
  background: #1c944e;
}

/* Image container to handle transitions */
.recent .img {
  position: relative;
  height: 200px;
  overflow: hidden;
}

.contact-icons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

@media (max-width: 768px) {
  .recent .content {
    grid-template-columns: 1fr;
  }

  .recent .verified {
    font-size: 12px;
  }

  .recent .verified i {
    font-size: 18px;
  }
}
