

.about {
  margin-bottom: 80px;
}
.about .heading {
  text-align: left;
  width: 100%;
  margin-bottom: 30px;
}

.error-message {
  color: red;
  font-weight: bold;
}

/* Checkbox container styling */
.checkbox-wrapper-46 {
  display: flex;
  align-items: center; /* Align items vertically in the center */
  margin-bottom: 10px; /* Space between checkboxes */
}

/* Hide the default checkbox */
.checkbox-wrapper-46 input[type="checkbox"] {
  display: none;
  visibility: hidden;
}

/* Styling for the checkbox label */
.checkbox-wrapper-46 .cbx {
  display: flex;
  align-items: center; /* Align items in the center vertically */
  cursor: pointer;
}

/* Styling for the checkbox label text */
.checkbox-wrapper-46 .cbx span:last-child {
  padding-left: 8px;
  color: black; /* Set label text color to black */
}

/* Checkbox styling */
.checkbox-wrapper-46 .cbx span:first-child {
  width: 18px;
  height: 18px;
  border-radius: 3px;
  border: 1px solid #9098a9;
  background: #ffffff; /* White background for the checkbox */
  display: inline-block;
  vertical-align: middle;
  position: relative;
  margin-right: 8px; /* Space between checkbox and label */
  transition: all 0.2s ease;
}

/* SVG styling inside checkbox */
.checkbox-wrapper-46 .cbx span:first-child svg {
  position: absolute;
  top: 3px;
  left: 2px;
  fill: none;
  stroke: #ffffff;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 16px;
  stroke-dashoffset: 16px;
  transition: all 0.3s ease;
  transition-delay: 0.1s;
}

/* Checkbox checked state styling */
.checkbox-wrapper-46 .inp-cbx:checked + .cbx span:first-child {
  background: #506eec;
  border-color: #506eec;
  animation: wave-46 0.4s ease;
}

.checkbox-wrapper-46 .inp-cbx:checked + .cbx span:first-child svg {
  stroke-dashoffset: 0;
}

.checkbox-wrapper-46 .inp-cbx:checked + .cbx span:first-child:before {
  transform: scale(3.5);
  opacity: 0;
  transition: all 0.6s ease;
}

/* Hover effect for checkbox */
.checkbox-wrapper-46 .cbx:hover span:first-child {
  border-color: #506eec;
}

/* Animation for checked state */
@keyframes wave-46 {
  50% {
    transform: scale(0.9);
  }
}


.success-message {
  margin-top: 20px;
  color: green;
  font-weight: bold;
}

.about p {
  font-size: 15px;
  padding-right: 30px;
  margin-bottom: 20px;
  line-height: 30px;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.contact-form label {
  font-weight: bold;
}

.contact-form input,
.contact-form textarea {
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.contact-form textarea {
  resize: vertical;
  height: 100px;
}

.btn1 {
  padding: 10px 20px;
  background-color: #27ae60; /* Updated button color */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn1:hover {
  background-color: #1e874b; /* Slightly darker shade for hover */
}

/* Add this to your advertise.css */

input[type="file"] {
  display: block;
  margin-top: 10px;
  padding: 10px;
  border: 2px dashed #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

input[type="file"]:hover {
  background-color: #f0f0f0;
}


@media screen and (max-width: 768px) {
  .about .container {
    flex-direction: column;
  }
  .about p {
    padding: 0;
  }
  .about .container img {
    margin-top: 50px;
  }
}
