.form {
    --background: #ecf9f1;
    --input-focus: #2d8cf0;
    --font-color: #2c3e50;
    --font-color-sub: #95a5a6;
    --bg-color: #fff;
    --main-color: #27ae60;
    
    padding: 20px;
    margin: 50px auto;
    max-width: 350px; /* Adjusted width for better mobile scaling */
    background: var(--background);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;
    border-radius: 5px;
    border: 2px solid var(--main-color);
    box-shadow: 4px 4px var(--main-color);
  }
  
  .form > p {
    font-family: var(--font-DelaGothicOne);
    color: var(--font-color);
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
  }
  
  .form > p > span {
    font-family: var(--font-SpaceMono);
    color: var(--font-color-sub);
    font-weight: 600;
    font-size: 17px;
  }
  
  .oauthButton {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: auto 15px 15px auto;
    width: 100%;
    height: 40px;
    border-radius: 5px;
    border: 2px solid var(--main-color);
    background-color: var(--bg-color);
    box-shadow: 4px 4px var(--main-color);
    font-size: 16px;
    font-weight: 600;
    color: var(--font-color);
    cursor: pointer;
    transition: all 250ms;
    position: relative;
    overflow: hidden;
    z-index: 1;
  }
  
  .oauthButton::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    background-color: #27ae60;
    z-index: -1;
    box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
    transition: all 250ms;
  }
  
  .oauthButton:hover {
    color: #ecf9f1;
  }
  
  .oauthButton:hover::before {
    width: 100%;
  }
  
  .icon {
    width: 1.5rem;
    height: 1.5rem;
  }
  
  /* Responsive design adjustments for mobile */
  @media (max-width: 600px) {
    .form {
      max-width: 90%; /* Ensures the form takes up 90% of the screen width */
      padding: 15px;  /* Reduces padding for smaller screens */
      margin: 20px auto; /* Adjusts margin for mobile display */
    }
  
    .form > p {
      font-size: 18px; /* Slightly smaller text for better mobile fit */
    }
  
    .form > p > span {
      font-size: 15px;
    }
  
    .oauthButton {
      height: 35px; /* Reduces button height for smaller screens */
      font-size: 14px;
    }
  }
  