.footerContact {
  background-color: #27ae60;
  padding: 40px 0;
  color: #fff;
}

.footerContact h1 {
  font-size: 40px;
}

.footer-links {
  display: flex;
  gap: 20px; /* Add spacing between the links */
  margin-top: 20px; /* Adjust margin */
  padding-top: 20px; /* Add padding on top for spacing from logo */
}

.footer-links a {
  color: #ffffff; /* White color for links */
  text-decoration: none;
  font-size: 16px;
}

.footer-links a:hover {
  text-decoration: underline; /* Underline on hover */
}

footer {
  background-color: #1d2636;
  padding: 50px 0;
  color: #fff;
}

footer .container {
  display: grid;
  grid-template-columns: 6fr 2fr 2fr 1fr;
  grid-gap: 20px;
}

footer img {
  width: 150px;
}

footer h2 {
  font-weight: 500;
}

footer p {
  color: grey;
  margin: 20px 0;
}

footer .input {
  display: flex;
  align-items: center;
  width: 100%;
}

footer input {
  background-color: #fff;
  padding: 17px;
  flex: 1;
  border-radius: 5px 0 0 5px;
  border: none;
  font-size: 16px;
}

footer button {
  padding: 14px 20px;
  background-color: #27ae60;
  color: #fff;
  border: none;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  font-size: 16px;
}

footer button:hover {
  background-color: #219150;
}

footer h3 {
  font-weight: 500;
  margin-bottom: 30px;
}

footer ul {
  display: block;
}

footer ul li {
  display: block;
  color: grey;
  margin-bottom: 20px;
}

.legal {
  text-align: center;
  padding: 20px;
  background: #1d2636;
  color: rgba(255, 255, 255, 0.5);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

@media screen and (max-width: 800px) {
  footer .container {
    grid-template-columns: repeat(2, 1fr);
  }

  footer .input {
    flex-direction: column;
  }

  footer input {
    border-radius: 5px;
    margin-bottom: 10px;
  }

  footer button {
    border-radius: 5px;
    width: 100%;
  }

  /* New styles for mobile alignment of footer links */
  .footer-links {
    display: block; /* Change to block for vertical stacking */
    margin-top: 10px; /* Optional: Adjust margin for spacing */
    gap: 0; /* Remove gap since links will stack */
  }

  .footer-links a {
    display: block; /* Stack links vertically */
    margin-bottom: 10px; /* Add spacing between links */
  }
}
