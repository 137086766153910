.verification-card {
  margin: 40px auto;
  padding: 30px;
  max-width: 500px;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

.card-container {
  text-align: center;
}

.verification-card h2 {
  font-size: 24px;
  color: #2c3e50;
  margin-bottom: 20px;
}

.verification-card p {
  font-size: 16px;
  color: #7f8c8d;
  margin-bottom: 20px;
}

.verification-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-group {
  text-align: left;
}

.verification-form label {
  font-weight: 600;
  color: #34495e;
  margin-bottom: 8px;
  display: block;
}

.verification-form input {
  width: 100%;
  padding: 12px;
  border: 1px solid #bdc3c7;
  border-radius: 6px;
  font-size: 14px;
  background-color: #ecf0f1;
  box-sizing: border-box;
}

.verification-form input:focus {
  border-color: #27ae60;
  outline: none;
  background-color: #fff;
}

.btn-submit {
  padding: 12px 20px;
  background-color: #27ae60;
  color: #ffffff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.btn-submit:hover {
  background-color: #2980b9;
}

.success-message {
  margin-top: 20px;
  color: #27ae60;
  font-weight: 600;
}
