/* Loading Container */
.loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
  }
  
  /* Spinner Animation */
  .loading-spinner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 60px;
    height: 60px;
  }
  
  /* Circle Animation */
  .loading-circle {
    width: 15px;
    height: 15px;
    background-color: #3498db;
    border-radius: 50%;
    animation: loadingBounce 1.2s infinite ease-in-out both;
  }
  
  /* Animation delay for the circles */
  .loading-circle:nth-child(1) {
    animation-delay: -0.32s;
  }
  
  .loading-circle:nth-child(2) {
    animation-delay: -0.16s;
  }
  
  @keyframes loadingBounce {
    0%, 80%, 100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }
  