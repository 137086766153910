.how-it-works {
    margin-top: 50px;
    padding: 20px 0;
  }
  
  .how-it-works .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .tab-navigation {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .tab-button {
    padding: 10px 20px;
    margin: 0 10px;
    border: none;
    background-color: #e0e0e0;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .tab-button.active {
    background-color: #27ae60;
    color: white;
  }
  
  .tab-button:hover {
    background-color: #27ae60;
  }
  
  .how-it-works-box {
    width: 100%;
    max-width: 1200px;
  }
  
  .how-it-works-section {
    padding: 20px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    border-radius: 8px;
  }
  
  .how-it-works-section h3 {
    font-size: 1.5rem;
    margin-bottom: 15px;
    color: #333;
  }
  
  .how-it-works-section p {
    margin-bottom: 10px;
    line-height: 1.6;
    color: #555;
  }
  
  .how-it-works-section p:first-of-type {
    font-weight: bold;
  }
  